import logo from './logo.svg';
import './App.css';
import FilepondUploader from "./FilepondUploader";


function App() {

    return (
      <div>
        <FilepondUploader/>
      </div>
  );
}

export default App;
