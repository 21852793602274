import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {FilePond, registerPlugin} from "react-filepond";

import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import axios from "axios";
import AIWarningModal from "./AIWarningModal";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


function FilepondUploader() {
    const [files, setFiles] = useState([]);
    const [fileFolders, setFileFolders] = useState([]);
    const [aiResults, setAiResults] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [websocketResults, setWebsocketResults] = useState(null);
    const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(true);

    useEffect(() => {
        window.Pusher = require('pusher-js');


        window.Echo = new Echo({
            broadcaster:        'pusher',
            key:                'ABCDEFG',
            wsHost:             'yannic-sockets.devdorado.co',
            wsPort:              6001,
            //wssPort:             6001,
            forceTLS:           false,
            disableStats:       true,
            scheme: "http",
            //enabledTransports:  ['ws', 'wss'],
        });

        window.Echo.channel('testApp').listen('.pendingVideoResultUpdated', function (data) {
            console.log("Echo Channel reached via window.Echo");

            let folder = data["folder"];
            let detected = data["detectionOutput"]["detected"];
            let detections = data["detectionOutput"]["detections"];
            let aiResultsEntry = {folder: folder, pending: false, detected: detected, detections: detections};
            setWebsocketResults(aiResultsEntry);
            //TODO: hier eventuelle animationen zum Warten stoppen!
        });
    }, []);

    /*useEffect(() => {
        //axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

        //const pusher = Pusher;
        window.Pusher = require('pusher-js');
        const echo = new Echo({
            broadcaster: 'pusher',
            //key: process.env.REACT_APP_PUSHER_PUBLIC_KEY,
            key: '0cccb20f475ddd0cddfd',
            //cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            cluster: 'eu',

            forceTLS: true,
        });

        const channel = echo.channel('testApp');
        channel.listen('.pendingVideoResultUpdated', function (data) {
            let folder = data["folder"];
            let detected = data["detectionOutput"]["detected"];
            let detections = data["detectionOutput"]["detections"];
            let aiResultsEntry = {folder: folder, pending: false, detected: detected, detections: detections};
            setWebsocketResults(aiResultsEntry);
            //TODO: hier eventuelle animationen zum Warten stoppen!
        });
    }, []);*/

    useEffect(() => {
        if (websocketResults == null) return;

        let webSocketResult = websocketResults;
        let folder = webSocketResult['folder'];
        let filteredAiResults = aiResults.filter(aiResult => aiResult['folder'] !== folder);
        setAiResults([...filteredAiResults, webSocketResult]);
        //TODO: hier eventuelle animationen zum Warten stoppen!
    }, [websocketResults]);


    useEffect(() => {
        console.log("useEffect fileFolder: " + fileFolders[fileFolders.length - 1]);
        if (fileFolders[0] != null) {
            axios.get(process.env.REACT_APP_API_URL + '/getAiResult/' + fileFolders[fileFolders.length - 1]).then(
                result => {

                    let folder = result.data[0]
                    let pending = result.data[1]
                    let detected = result.data[2]
                    let detections = result.data[3]
                    let aiResultsEntry = {folder: folder, pending: pending, detected: detected, detections: detections};

                    let filteredAiResults = aiResults.filter(aiResult => aiResult['folder'] !== folder);
                    setAiResults([...filteredAiResults, aiResultsEntry]);
                }
            ).catch((errors) => {
                alert(errors)
            });
        } else {
            console.log("no fileFolder found: allways happens on submit");
        }
    }, [fileFolders]);


    useEffect(() => {
        if (aiResults[0] != null) {
            if (!aiResults[aiResults.length - 1]["pending"] && aiResults[aiResults.length - 1]["detected"]) {
                setShowModal(true);
            }
        }
    }, [aiResults]);

    function checkForAIDetections() {

    }

    const handleSubmit = (e) => {
        e.preventDefault();


        //TODO: daten abspeichern ... brauche user daten und storage place

        console.log("aiResults: " + aiResults);
        console.log("submit fileFolder: " + fileFolders[fileFolders.length - 1]);
        setFileFolders([]); //nötig wenn in der FilePond onload funktion {setFileFolders(fileFolders => [...fileFolders, response])} benutz wurde

        setAiResults([]);
        setFiles([]);
        e.target.reset();
    }

    function handleUpdateVideoResultClick() {
        if (fileFolders[0] != null) {
            axios.get(process.env.REACT_APP_API_URL + '/getAiResult/' + fileFolders[fileFolders.length - 1]).then(
                result => {
                    setAiResults(aiResults => [...aiResults, JSON.stringify(result.data)])
                }
            ).catch((errors) => {
                alert(errors)
            });
        } else {
            console.log("no fileFolder found");
        }
    }

    function handleOnGoingVideoClick() {
        axios.post(process.env.REACT_APP_API_URL + '/logOngoingVideoJobs');
    }


    return (
        <div class="p-4 m-2">
            <form onSubmit={handleSubmit} id="uploadForm">
                <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={20}
                    server={
                        {
                            process: {
                                url: process.env.REACT_APP_API_URL + "/filepondUpload",
                                onload: (response) => {
                                    setFileFolders(fileFolders => [...fileFolders, response])
                                    console.log("onload :" + fileFolders[fileFolders.length - 1])
                                    return response // added
                                }
                            },
                            revert: {
                                url: process.env.REACT_APP_API_URL + "/revertFilepondUpload"
                            }
                        }

                    }
                    name="files"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
                <input class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit"
                       value="submit"/>
            </form>
            <div>
                Ai Output:
                <p>{JSON.stringify(aiResults)}</p>
            </div>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
                    onClick={handleUpdateVideoResultClick}>UpdateVideoResult
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
                    onClick={handleOnGoingVideoClick}>log on-going-video-jobs
            </button>
            <button
                className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 m-4 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Open regular modal
            </button>
            <AIWarningModal showModal={showModal} setShowModal={setShowModal} aiResults={aiResults}/>
        </div>
    )
}

export default FilepondUploader;